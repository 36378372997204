
// WhatsAppIcon.js
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import the WhatsApp icon from react-icons
import './WhatsAppIcon.css';

const WhatsAppIcon = () => {
  return (
    <a
      href="https://wa.me/<917986450528>" // Add your WhatsApp number here
      className="whatsapp-float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppIcon;
