import React, { Fragment, useEffect, useState, Suspense, lazy } from "react";
import { Rating } from "@material-ui/lab";
import { Link } from "react-router-dom";
import "./Home.css";
import MetaData from "../layout/MetaData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader"; 
import { useAlert } from "react-alert";
import Bestseller from "./Bestseller";
import '../Product/ProductDetails.css'

const ratingValue = 5;

const ratingReadOnly = true;
const ratingSize = "small";

const ratingComponent = (
  <Rating name="read-only" value={ratingValue} readOnly={ratingReadOnly} size={ratingSize} />
);

export const Shoppingbtns = ({ terraceLink, terraceText, shopAllLink, shopAllText }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Link to={terraceLink}>
        <button type="button" className="custom-btn btn-15">
          {terraceText}
        </button>
      </Link>
      <Link to={shopAllLink}>
        <button className="btnadde">{shopAllText}</button>
      </Link>
    </div>
  );
};

const button1 =   (
  <button className="btnadd buttonfont"
                    
  >
  View Product
  </button>
);


const Indoorplants = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  // new addde
  const images = [
    'https://res.cloudinary.com/dc5luxn64/image/upload/v1692698492/Backgrounds/indoor_plants_hd_bomosk.webp',
    'https://res.cloudinary.com/dc5luxn64/image/upload/v1689830607/Backgrounds/girl_shopping_w0tdmc.webp',
  
  ];
    const laptopImage = [
      'https://res.cloudinary.com/dc5luxn64/image/upload/v1692698492/Backgrounds/indoor_plants_hd_bomosk.webp',
      'https://res.cloudinary.com/dc5luxn64/image/upload/v1689830607/Backgrounds/girl_shopping_w0tdmc.webp',
      
  ];



  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length );
    }, 3000);
    
    return () => {
      clearInterval(interval);
    };
  },
   [images.length ]);

// to here
 const fruitsmall1 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1692608039/products/n7kymkgevwespzpq4d3k.webp'
 const fruitsmall2 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1685522317/products/rp2cqsun8cdjtjciftg9.webp'
 const fruitsmall3 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689237713/products/g09nuupboztmt0uvuv7x.webp'
 const fruitsmall4 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1683907582/products/cvmps2jcgyr1bvxkcbnx.webp'



 
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="homepage">
          <MetaData title="Terrace Garden" />

<div className="app__bg3NW app__headerNW app__wrapperNW section__paddingNW" id="home">
  <picture>
    <source
      media="(min-width: 768px)"
      srcSet={`${laptopImage[currentImageIndex]} 1x, ${laptopImage[currentImageIndex]} 2x`}
    />
    <img
      srcSet={`${images[currentImageIndex]} 1x, ${images[currentImageIndex]} 2x`}
      src={images[currentImageIndex]} 
      alt="terrace garden page"
      className="background-imageNW"
      loading="lazy" 
    />
  </picture>
</div>
          {/* gap */}
          <div className="gap2"></div>

          <div id="Fives">
          <h4 className='mb-6'  >Indoor Plants </h4>

          <h5 className='paraof' >Welcome to our Indoor Plants collection, specially curated for low-light environments. Discover our range of low-light indoor plants, paired with Heavy-duty and charming pots. Transform your indoor spaces into lush oases, even in areas with limited light conditions, with our carefully selected green companions.</h5>
          <Suspense fallback={<div>Loading...</div>}>
          <Bestseller 
            bestlink1='/product/64609bb53a1e6e22386e2005'
            bestlink2='/product/6477078ec4f78b4c973c9d43'
            bestlink3='/product/64afb8ca6346e802c1c71379'
            bestlink4='/product/645e5d93ceeeef0818b433fa'
            pricename = 'pricename'
            underline1 =  ' 6ft XXL potted Areca in 14inch [20ltr] pot'
            underline2 =  '6ft XXL potted Palm in 16inch [30ltr] pot'
            underline3 =  'Snake Plant 130cm potted plant in 14 inches [20ltr] pot'
            underline4 =  'Raphis Palm  Large (5.5ft) in 16inch [20ltr] pot '
              title1="Areca Palm" 
              title2="Bamboo Palm"
              title3="Snake plant"
              title4="Raphis Palm"
           
              image1={fruitsmall1} alt1='Areca Palm'
              image2={fruitsmall2}alt2='Balcony Plants'
              image3={fruitsmall3} alt3='Palm plants'
              image4={fruitsmall4}alt4='Raphis Palm'
              priceofprod1='₹1299/-'
              priceofprod2='₹1699/-'
              priceofprod3='₹1699/-'
              priceofprod4='₹2399/-'
         ratingno1='(1)'
         ratingno2='(1)'
         ratingno3='(1)'
         ratingno4='(1)'
         ratingComponent={ratingComponent}
         button={button1}

         realprice1='1999'
            realprice2='2299'
            realprice3='2299'
            realprice4='3999'
            /></Suspense>
        </div>
 


      

 </div>
   
         
      )}
    </Fragment>
  );
};

export default Indoorplants;



