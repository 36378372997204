import React from 'react';
import {  BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';



import './Galleryof.css';

const Galleryof = () => {
const images = {
  a1 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688380099/our%20work/5.2_jlp14s.webp',
  a2 : 'https://res.cloudinary.com/dc5luxn64/image/upload/a_090/v1686985868/Backgrounds/terrace_garden_chairs_dlywk7.webp',
  a3 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129824/our%20work/costomer_terrace_nish_trpltv.webp',
  a4 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688380278/our%20work/4_mbn1xy.webp',
  a5 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684128095/our%20work/selloum_customer_fqarsm.webp',
  a6 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688380100/our%20work/6_qfqusg.webp',
   a7 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688380099/our%20work/1_xysjib.webp',
   
   a8 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129826/our%20work/terrace_garden_saompj.webp',
   a9 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129825/our%20work/path_pots_uv9jab.webp',
   a10 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688380099/our%20work/2_pwoccn.webp',

   a11 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688380099/our%20work/3_nez5ie.webp',
   a12 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129825/our%20work/narangi_ugrt0r.webp',

   a13 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129824/our%20work/eagle_terrace_m0epbf.webp',

   a14 : 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129824/our%20work/kinno_large_eguv2r.webp',

   a15: 'https://res.cloudinary.com/dc5luxn64/image/upload/v1686144363/our%20work/anjeer_and_papaya_in_black_pot_terrace_garden_nr0kpv.webp',
   a16: 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129824/our%20work/large_pots_ficus_m3z9vs.webp',
   a17: 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129825/our%20work/terace_nis_ekplcl.webp',
   a18:'https://res.cloudinary.com/dc5luxn64/image/upload/v1684129826/our%20work/uncle_terrace_y8cg09.webp',
   a19: 'https://res.cloudinary.com/dc5luxn64/image/upload/v1685542988/our%20work/customer_terrace_garden_1_km8dpa.webp',
}



  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className=" app__gallery flex__center">
      
    <h4 className="mb-6">Share #Forestmakers </h4>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[images.a1, images.a2, images.a3, images.a4, images.a5,images.a6,images.a7,images.a8,images.a9,images.a10,images.a11,images.a12,images.a13,images.a14,images.a15,images.a16,images.a17,images.a18,images.a19,].map((image, index) => (
            <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
           
            <img src={image} alt="gallery_image"  loading="lazy"/> 
         
          
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default Galleryof;
