import React, { Fragment, useEffect, useState, Suspense, lazy } from "react";
import { Rating } from "@material-ui/lab";
import { Link } from "react-router-dom";
import "./Home.css";
import MetaData from "../layout/MetaData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader"; 
import { useAlert } from "react-alert";
import Bestseller from "./Bestseller";
import '../Product/ProductDetails.css'




const ratingValue = 5;

const ratingReadOnly = true;
const ratingSize = "small";

const ratingComponent = (
  <Rating name="read-only" value={ratingValue} readOnly={ratingReadOnly} size={ratingSize} />
);
// const ratingComponent2 = (
//   <Rating name="read-only" value={ratingValue2} readOnly={ratingReadOnly} size={ratingSize} />
// );

export const Shoppingbtns = ({ terraceLink, terraceText, shopAllLink, shopAllText }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Link to={terraceLink}>
        <button type="button" className="custom-btn btn-15">
          {terraceText}
        </button>
      </Link>
      <Link to={shopAllLink}>
        <button className="btnadde">{shopAllText}</button>
      </Link>
    </div>
  );
};

const button1 =   (
  <button className="btnadd buttonfont"
                    
  >
  View Product
  </button>
);


const Pottedplants = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  // new addde
  const images = [
   
    
    
    'https://res.cloudinary.com/dc5luxn64/image/upload/v1689749570/Backgrounds/girl_wit_pots_qysnsg.webp',
  
  ];
   
   
 
  const laptopImage = [
      'https://res.cloudinary.com/dc5luxn64/image/upload/v1689749570/Backgrounds/girl_wit_pots_qysnsg.webp',
      
  ];

 



  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length );
    }, 3000);
    
    return () => {
      clearInterval(interval);
    };
  },
   [images.length ]);


  
  


   
// to here
 const fruitsmall1 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688465647/products/u0lsfwy2aq05wccebete.webp'
 const fruitsmall2 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1687533312/products/bm9qm2e17iqwjtw07bab.webp'
 const fruitsmall3 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688465865/products/toaemvakpazxpxcb3xjr.webp'
 const fruitsmall4 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689244486/products/akeujhcm6ncmx89zd3yn.webp'

  
const line2img1 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689237713/products/g09nuupboztmt0uvuv7x.webp'
 const line2img2 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688465242/products/mq8x2gubpuremk85rzxm.webp'
 const line2img3 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688465133/products/qzxb1uxednpysmkhdlhz.webp'
 const line2img4 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689493231/products/xhj0n4v6n0j3noaw4dma.webp'




const line3img1 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1685522317/products/rp2cqsun8cdjtjciftg9.webp'
const line3img2 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689499223/products/kk3ebgpinpmxbzvlxfep.webp'
const line3img3 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688465340/products/t8w9e6br1hlhnzvpfcjl.webp'
const line3img4 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689499707/products/onoc9a29tdjserhmd4jd.webp'

const line4img1 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1690215134/products/v31n2hyvq13cn4ql6g8b.webp'
const line4img2 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1690113029/products/kzmctvyiyv8knxawz3pa.webp'
const line4img3 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1690215991/products/gyvrkl2hulbe7lnqujpy.webp'
const line4img4 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689235411/products/tgc39zbwgwy7mqbvojkn.webp'
 
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="homepage">
          <MetaData title="Potted plants" />

<div className="app__bg3NW app__headerNW app__wrapperNW section__paddingNW" id="home">
  <picture>
    <source
      media="(min-width: 768px)"
      srcSet={`${laptopImage[currentImageIndex]} 1x, ${laptopImage[currentImageIndex]} 2x`}
    />
    <img
      srcSet={`${images[currentImageIndex]} 1x, ${images[currentImageIndex]} 2x`}
      src={images[currentImageIndex]} 
      alt="terrace garden page"
      className="background-imageNW"
      loading="lazy" 
    />
  </picture>
</div>

       

          {/* gap */}
          <div className="gap2"></div>

        
{/* from here */}
          {/* gap */}
          {/* <div className="gap"></div> */}
       
       


          <div id="Fives">
          <h4 className='mb-6'  >Potted-Plants </h4>
          <h5 className='paraof' >Discover our collection of potted plants, perfectly nestled in large pots ranging from 14 to 24 inches. Delivered right to your doorstep, our lightweight potting soil ensures hassle-free gardening. </h5>
          <Suspense fallback={<div>Loading...</div>}>
          <Bestseller 
            bestlink1='/product/6495bd31449bd41da0a8bf44'
            bestlink2='/product/6495b700449bd41da0a8bf09'
            bestlink3='/product/6495b9d7449bd41da0a8bf25'
            bestlink4='/product/6495be7a449bd41da0a8bf66'
            pricename = 'pricename'
            underline1 =  'Narangi potted plant in 14 inches [20ltr] pot'
            underline2 =  'Guava potted plant in 14 inches [20ltr] pot'
            underline3 =  'Mango potted plant in 14 inches [20ltr] pot'
            underline4 =  'Nimbu potted plant in 14 inches [20ltr] pot '
              title1="Narangi" 
              title2="Guava pink"
              title3="Mango"
              title4="Nimbu"
              price1='New'
              price4='Bestseller'
              image1={fruitsmall1} alt1='Narangi'
              image2={fruitsmall2}alt2='Guava'
              image3={fruitsmall3} alt3='Mango'
              image4={fruitsmall4}alt4='Nimbu'
              priceofprod1='₹849/-'
              priceofprod2='₹849/-'
              priceofprod3='₹889/-'
              priceofprod4='₹899/-'
         ratingno1='(3)'
         ratingno2='(4)'
         ratingno3='(2)'
         ratingno4='(15)'
         ratingComponent={ratingComponent}
         button={button1}

         Tag1 ='tagbadge'
         Tagin1='tagbadgein'
         Tag4 ='tagbadge'
         Tagin4='tagbadgein'

         realprice1='₹1449'
         realprice2='₹1449'
         realprice3='₹1489'
         realprice4='₹1499'
            /></Suspense>
        </div>
 
 {/* gap */}
 <div className="gap2"></div>

          <div id="balconygarden">
         
          <Suspense fallback={<div>Loading...</div>}>
          <Bestseller 
            bestlink1='/product/64afb8ca6346e802c1c71379'
            bestlink2='/product/649727186346e802c1c708d8'
            bestlink3='/product/64972a346346e802c1c7091c'
            bestlink4='/product/64b39ee76346e802c1c71982'
            pricename = 'pricename'
            underline1 =  'Snake Plant potted plant in 14inch [20ltr] pot'
            underline2 =  'Golden bamboo potted plant in 14 inches [20ltr] pot'
            underline3 =  'Anjeer Plant potted plant in 14 inches [20ltr] pot'
            underline4 =  'Moringa potted plant in 20inch Large [60ltr] pot'
              title1="Snake Plant" 
              title2="Bamboo plant"
              title3="Anjeer"
              title4="Moringa"
          
              image1={line2img1} alt1='Snake'
              image2={line2img2} alt2='Golden Bamboo'
              image3={line2img3} alt3='anjeer'
              image4={line2img4} alt4='moringa'
              priceofprod1='₹1699/-'
              priceofprod2='₹889/-'
              priceofprod3='₹889/-'
              priceofprod4='₹1499/-'

              ratingComponent={ratingComponent}
            ratingno1='(7)'
            ratingno2='(5)'
            ratingno3='(1)'
            ratingno4='(2)'
            button={button1}

            realprice1='₹2299'
            realprice2='₹1489'
            realprice3='₹1489'
            realprice4='₹2099'
            /></Suspense>
        </div>

   {/* gap */}
 <div className="gap2"></div>

        <div id="fruitgarden">
      
        <Suspense fallback={<div>Loading...</div>}>
        <Bestseller 
        bestlink1='/product/6477078ec4f78b4c973c9d43'
        bestlink2='/product/64b3b64e6346e802c1c71a4d'
        bestlink3='/product/64971df56346e802c1c708bb'
        bestlink4='/product/64b3b8326346e802c1c71a75'
        pricename = 'pricename'
        title1 = 'Bamboo Palm' 
        title2 = 'Pomegranate'
        title3 = 'Bamboo'
        title4 = 'Karonda'
        underline1 =  'Bamboo palm Large 6ft potted in 16inch black pot'
        underline2 =  'Pomegranate Plant Potted in 20inch large [60ltr] pot'
        underline3 =  'Bamboo green potted plant 14inch [20ltr] pot'
        underline4 =  'Karonda Plant Potted in 20inch large [60ltr] pot'
      
        priceofprod1='₹1699/-'
        priceofprod2='₹1499/-'
        priceofprod3='₹849/-'
        priceofprod4='₹1489/-'
        image1 ={line3img1} alt1='Bamboo Palm'
        image2={line3img2} alt2='Anar'
        image3={line3img3} alt3='Bamboo'
        image4={line3img4} alt4='Karonda'
        ratingComponent={ratingComponent}
        ratingno1='(3)'
        ratingno2='(1)'
        ratingno3='(4)'
        ratingno4='(1)'

        button={button1}
price3='Fast Growing'
        Tag3 ='tagbadge'
         Tagin3='tagbadgein'

         realprice1='₹2299'
         realprice2='₹2099'
         realprice3='₹1449'
         realprice4='₹2189'
        /></Suspense>
        </div>

        <div className="gap2"></div>

<div id="fruitgarden">

<Suspense fallback={<div>Loading...</div>}>
<Bestseller 
bestlink1='/product/64bea2d1ede7f202c17aac4b'
bestlink2='/product/64bd13f7ede7f202c17aa9e6'
bestlink3='/product/64bea629ede7f202c17aac7c'
bestlink4='/product/64afafcb6346e802c1c71353'
pricename = 'pricename'
title1 = 'Bael Plant ' 
title2 = 'Chiku Plant'
title3 = 'Shami Plant'
title4 = 'Harsingar'
underline1 =  'Bael Plant potted plant in 14inch [20ltr] pot'
underline2 =  'Chiku Plant potted plant in 14-24inch [20ltr] pots'
underline3 =  'Shami Plant potted plant in 14inch [20ltr] pot'
underline4 =  'Harsingar Plant potted plant in 14inch [20ltr] pot'

priceofprod1='₹699/-'
priceofprod2='₹989/-'
priceofprod3='₹699/-'
priceofprod4='₹889/-'
image1 ={line4img1} alt1='Bamboo Palm'
image2={line4img2} alt2='Anar'
image3={line4img3} alt3='Bamboo'
image4={line4img4} alt4='Karonda'
ratingComponent={ratingComponent}
ratingno1='(1)'
ratingno2='(1)'
ratingno3='(1)'
ratingno4='(1)'

button={button1}
price3='Sacred'

Tag3 ='tagbadge'
Tag2 ='tagbadge'
 Tagin3='tagbadgein'

 realprice1='₹2299'
 realprice2='₹2099'
 realprice3='₹1449'
 realprice4='₹2189'
/></Suspense>
</div>



 </div>
   
         
      )}
    </Fragment>
  );
};

export default Pottedplants;



