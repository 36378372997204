import React, { Fragment, useEffect, useState, Suspense, lazy } from "react";
import { Rating } from "@material-ui/lab";
import { Link } from "react-router-dom";
import "./Home.css";
import MetaData from "../layout/MetaData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader"; 
import { useAlert } from "react-alert";
import Bestseller from "./Bestseller";
import '../Product/ProductDetails.css';

const ratingValue = 5;

const ratingReadOnly = true;
const ratingSize = "small";

const ratingComponent = (
  <Rating name="read-only" value={ratingValue} readOnly={ratingReadOnly} size={ratingSize} />
);

export const Shoppingbtns = ({ terraceLink, terraceText, shopAllLink, shopAllText }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Link to={terraceLink}>
        <button type="button" className="custom-btn btn-15">
          {terraceText}
        </button>
      </Link>
      <Link to={shopAllLink}>
        <button className="btnadde">{shopAllText}</button>
      </Link>
    </div>
  );
};

const button1 =   (
  <button className="btnadd buttonfont"
                    
  >
  View Product
  </button>
);


const Pottingsoil = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  // new addde
  const images = [
    'https://res.cloudinary.com/dc5luxn64/image/upload/v1684058942/productszoom/ngfcmxqem0kgf9xuegea.webp',
  
  ];
    const laptopImage = [
      'https://res.cloudinary.com/dc5luxn64/image/upload/v1684058942/productszoom/ngfcmxqem0kgf9xuegea.webp',
      
  ];



  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length );
    }, 3000);
    
    return () => {
      clearInterval(interval);
    };
  },
   [images.length ]);

// to here
 const fruitsmall1 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1683909646/products/cmegtfmojksdmsxkqlzp.webp'
 const fruitsmall2 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688487566/products/gcz4sa6weouj0no70epv.webp'
 const fruitsmall3 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1684058943/products/yup9dhgzupsdgfkcq38t.webp'
 const fruitsmall4 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688488230/products/rlncl5eeed28fseuv7ul.webp'



 
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="homepage">
          <MetaData title="Terrace Garden" />

<div className="app__bg3NW app__headerNW app__wrapperNW section__paddingNW" id="home">
  <picture>
    <source
      media="(min-width: 768px)"
      srcSet={`${laptopImage[currentImageIndex]} 1x, ${laptopImage[currentImageIndex]} 2x`}
    />
    <img
      srcSet={`${images[currentImageIndex]} 1x, ${images[currentImageIndex]} 2x`}
      src={images[currentImageIndex]} 
      alt="terrace garden page"
      className="background-imageNW"
      loading="lazy" 
    />
  </picture>
</div>
          {/* gap */}
          <div className="gap2"></div>

          <div id="Fives">
          <h4 className='mb-6'  >Filled-Pots & Potting soil </h4>
          <h5 className='paraof' >Enhance your terrace gardening experience with our premium lightweight potting soil blend, crafted from a combination of soil, cocopeat, and compost. Our large pots are ideal for cultivating a variety of vegetables, fruits, and herbs, making your garden flourish like never before. </h5>
          <Suspense fallback={<div>Loading...</div>}>
          <Bestseller 
            bestlink1='/product/645e65a3ceeeef0818b4342d'
            bestlink2='/product/64a44690cf754819b8e9f4cf'
            bestlink3='/product/6460b340a4f3441ceca6da97'
            bestlink4='/product/64a44872cf754819b8e9f4e8'
            pricename = 'pricename'
            underline1 =  ' Combination of soil, compost, and cocopeat 2litre'
            underline2 =  '5 Black filled Pots set of 20ltr [14inch] '
            underline3 =  'Combination of soil, compost and cocopeat 100ltr'
            underline4 =  'Wide range of filled Large pots 14-24inches '
              title1="Potting soil mix" 
              title2="Filled 5 Pots"
              title3="Potting soil Xl"
              title4="Filled Pot"
           
              image1={fruitsmall1} alt1='potting mix'
              image2={fruitsmall2}alt2='large black pots'
              image3={fruitsmall3} alt3='potting soil'
              image4={fruitsmall4}alt4='Large Pot'
              priceofprod1='₹149/-'
              priceofprod2='₹1389/-'
              priceofprod3='₹1299/-'
              priceofprod4='₹499/-'
         ratingno1='(1)'
         ratingno2='(2)'
         ratingno3='(4)'
         ratingno4='(2)'
         ratingComponent={ratingComponent}
         button={button1}

       price3='100 litres'
         Tag3 ='tagbadge'
         Tagin3='tagbadgein'

         realprice1='₹169'
         realprice2='₹1989'
         realprice3='₹1889'
         realprice4='₹700'
            /></Suspense>
        </div>
 
       



      

 </div>
   
         
      )}
    </Fragment>
  );
};

export default Pottingsoil;



