import React, { useState, useEffect } from 'react';
import '../../Product/ProductDetails.css'

const Productmob = (props) => {

  const [currentImage, setCurrentImage] = useState(0);
  const images = [props.image10, props.image11]; // Add additional image URLs here

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>

<div className="pdp-description">
<div className="pdp-description__image">
<div>
<img src={images[currentImage]}  alt="Large Pot" loading="lazy"/>    
                 
               
              </div>
</div>
<div className="pdp-description__text">
           <h4>{props.title}</h4>       <p>{props.para1}</p>
           <p>{props.para2}</p>
           <p> {props.para3}</p>
</div>

</div>
0 
    </div>
  )
}

export default Productmob
