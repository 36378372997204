import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  updateBlog,
  getBlogDetails,
  // getBlogCard,

} from "../../actions/blogAction";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../layout/MetaData";

import DescriptionIcon from "@material-ui/icons/Description";

import SpellcheckIcon from "@material-ui/icons/Spellcheck";

import SideBar from "./Sidebar";
import { UPDATE_BLOG_RESET } from "../../constants/blogConstants";

const UpdateBlog = ({ history, match }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, blog } = useSelector((state) => state.blogDetails);
 
  const {
    loading,
    error: updateError,
    isUpdated, 
  } = useSelector((state) => state.blog);

  const [date, setDate] = useState("");
  const [blogtitle, setBlogtitle] = useState("");
  
  const [pointone, setPointone] = useState("");
  const [pointtwo, setPointtwo] = useState("");
  const [pointthree, setPointthree] = useState("");
  const [pointfour, setPointfour] = useState("");
  const [pointfive, setPointfive] = useState("");
  const [pointsix, setPointsix] = useState("");

  const [headingone, setHeadingone] = useState("");
  const [headingtwo, setHeadingtwo] = useState("");
  const [headingthree, setHeadingthree] = useState("");
  const [headingfour, setHeadingfour] = useState("");
  const [headingfive, setHeadingfive] = useState("");
  const [phrase, setPhrase] = useState("");

  const [blogimages, setBlogimages] = useState([]);
  const [oldBlogimages, setOldBlogimages] = useState([]);
  const [blogimagesPreview, setBlogimagesPreview] = useState([]);

  // const [blogphotos, setBlogphotos] = useState([]);
  // const [oldBlogphotos, setOldBlogphotos] = useState([]);
  // const [blogphotosPreview, setBlogphotosPreview] = useState([]);

 

  const blogId = match.params.id;

  useEffect(() => {
    if (blog && blog._id !== blogId) {
      dispatch(getBlogDetails(blogId));
    } else {
      setDate(blog.date);
      setBlogtitle(blog.blogtitle);
      setPointone(blog.pointone);
      setPointtwo(blog.pointtwo);
      setPointthree(blog.pointthree);
      setPointfour(blog.pointfour);
      setPointfive(blog.pointfive);
      setPointsix(blog.pointsix);

      setHeadingone(blog.headingone);
      setHeadingtwo(blog.headingtwo);
      setHeadingthree(blog.headingthree);
      setHeadingfour(blog.headingfour);
      setHeadingfive(blog.headingfive);
      setPhrase(blog.phrase);

      setOldBlogimages(blog.blogimages);

      // setOldBlogphotos(blog.blogphotos);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("blog Updated Successfully");
      history.push("/admin/blogs");
      dispatch({ type: UPDATE_BLOG_RESET });
    }
  }, [
    dispatch,
    alert,
    error,
    history,
    isUpdated,
   blogId,
    blog,
    updateError,
  ]);

  const updateBlogSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("date", date);
    myForm.set("blogtitle", blogtitle);
    myForm.set("pointone", pointone);
    myForm.set("pointtwo", pointtwo);
    myForm.set("pointthree", pointthree);
    myForm.set("pointfour", pointfour);
    myForm.set("pointfive", pointfive);
    myForm.set("pointsix", pointsix);

    myForm.set("headingone", headingone);
    myForm.set("headingtwo", headingtwo);
    myForm.set("headingthree", headingthree);
    myForm.set("headingfour", headingfour);
    myForm.set("headingfive", headingfive);
    myForm.set("phrase", phrase);

    blogimages.forEach((blogimage) => {
      myForm.append("blogimages", blogimage);

     
  
    });
 
//  blogphotos.forEach((blogphoto) => {
//     myForm.append("blogphotos", blogphoto);

//   });

    dispatch(updateBlog(blogId, myForm));
  };

  const updateBlogImagesChange = (e) => {
    const files = Array.from(e.target.files);

    setBlogimages([]);
    setBlogimagesPreview([]);
    setOldBlogimages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setBlogimagesPreview((old) => [...old, reader.result]);
          setBlogimages((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };



  // const updateBlogPhotosChange = (e) => {
  //   const files = Array.from(e.target.files);

  //   setBlogphotos([]);
  //   setBlogphotosPreview([]);
  //   setOldBlogphotos([]);

  //   files.forEach((file) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setBlogphotosPreview((old) => [...old, reader.result]);
  //         setBlogphotos((old) => [...old, reader.result]);
  //       }
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // };
  return (
    <Fragment>
      <MetaData title="Create Product" />
      <div className="dashboard">
        <SideBar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={updateBlogSubmitHandler}
          >
            <h1>Update Blog</h1>

            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="Blog title"
                required
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          {/* new adde point one descripton */}
<div>
              <DescriptionIcon/>

              <textarea
                placeholder="blogtitle"
                value={blogtitle}
                onChange={(e) => setBlogtitle(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>
{/* point 1 */}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="Point 1"
                value={pointone}
                onChange={(e) => setPointone(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>

              {/* heading one */}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="heading 1"
                value={headingone}
                onChange={(e) => setHeadingone(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>  

           {/* point 2*/}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="point 2"
                value={pointtwo}
                onChange={(e) => setPointtwo(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>

                {/* heading one */}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="heading 2"
                value={headingtwo}
                onChange={(e) => setHeadingtwo(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>  

{/* new adde point three descripton */}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="point 3"
                value={pointthree}
                onChange={(e) => setPointthree(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>

               {/* heading three */}
           <div>
              <DescriptionIcon />

              <textarea
                placeholder="heading 3"
                value={headingthree}
                onChange={(e) => setHeadingthree(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>  

{/* new adde point two descripton */}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="point 4"
                value={pointfour}
                onChange={(e) => setPointfour(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>


                       {/* heading one */}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="heading 4"
                value={headingfour}
                onChange={(e) => setHeadingfour(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>  

{/* point 5*/}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="Point 5"
                value={pointfive}
                onChange={(e) => setPointfive(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>

                            {/* heading one */}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="heading 5"
                value={headingfive}
                onChange={(e) => setHeadingfive(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>  

{/* 6*/}
<div>
              <DescriptionIcon />

              <textarea
                placeholder="point 6"
                value={pointsix}
                onChange={(e) => setPointsix(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>

        {/* phrase*/}
        <div>
              <DescriptionIcon />

              <textarea
                placeholder="phrase"
                value={phrase}
                onChange={(e) => setPhrase(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>  



       
           
{/* images */}

            

          

            <div id="createProductFormFile">
              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={updateBlogImagesChange}
                multiple
              />
            </div>

            <div id="createProductFormImage">
              {oldBlogimages &&
                oldBlogimages.map((blogimage, index) => (
                  <img key={index} src={blogimage.url} alt="Old Product Preview" />
                ))}
            </div>
            <div id="createProductFormImage">
              {blogimagesPreview.map((blogimage, index) => (
                <img key={index} src={blogimage} alt="Product Preview" />
              ))}
            </div>

           
{/* photos */}

{/* <div id="createProductFormFile">
              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={updateBlogPhotosChange}
                multiple
              />
            </div>
            <div id="createProductFormImage">
              {oldBlogphotos &&
                oldBlogphotos.map((blogphoto, index) => (
                  <img key={index} src={blogphoto.url} alt="Old Product Preview" />
                ))}
            </div>
            <div id="createProductFormImage">
              {blogphotosPreview.map((blogphoto, index) => (
                <img key={index} src={blogphoto} alt="Product Preview" />
              ))}
            </div> */}

            

{/* button */}


            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateBlog