import React from 'react';
import './Faquestion.css';



function Career(props) {
  
  
  return (
    <div className="faq-container ">
      <h2 className='faq-faq'>{props.location.state.heading}</h2>
     
    <div style={{ justifyContent: 'space between'}}>
          <h3 className='queston'>{props.location.state.detail}</h3>
          <h3 className='queston'>{props.location.state.detail1}</h3>
       
          </div> 
  
    </div>
  );
}

export default Career;
