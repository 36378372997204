import React from 'react'
import  '../layout/Productlist/Productlist.css'
import "./Home.css";
import { Link } from 'react-router-dom'

const Bestseller = (props ) => {
  const { ratingComponent } = props;

  return (
    <div className='listproduct'>

  <div className="main1">
    <div className="grid grid--product">
    <Link to={props.bestlink1}>
      <div className="grid__item">

      <div className={props.Tag1}>
         <div className={props.Tagin1}>{props.price1}</div>
        </div>
        <img src={props.image1} alt={props.alt1} loading='lazy'/>
      
        <div className={props.pricename}>  <p style={{ fontSize: props.fontSize1 }}>{props.title1}</p>
        
        <p>{props.priceofprod1} <li className="realprice1" >{props.realprice1}</li></p>

        </div>
         <li className='productpara'>
       {props.underline1}
      </li>
      <div className="detailsBlock-2 justify"> {ratingComponent}
     <span className="detailsBlock-2-span">{props.ratingno1}</span>   
      </div>
      <div>{props.button}</div>
      </div>
      
      </Link>

      <Link to={props.bestlink2}> 


      
      <div className="grid__item">

      <div className={props.Tag2}>
         <div className={props.Tagin2}>{props.price2}</div>
        </div>


        <img src={props.image2} alt={props.alt2} loading='lazy'/>
        <div className={props.pricename}>  <p style={{ fontSize: props.fontSize1 }}>{props.title2}</p>
        <p>{props.priceofprod2} 
        <li className="realprice1" >{props.realprice2}</li>
        </p>
        
        </div>
         <li className='productpara' >
       
       {props.underline2}
      
      </li>
      <div className="detailsBlock-2 justify">{ratingComponent}
     <span className="detailsBlock-2-span">{props.ratingno2}</span>   
      </div>
      <div>{props.button}</div>
      </div>
      </Link>
      <Link to={props.bestlink3} >
      <div className="grid__item">
      <div className={props.Tag3}>
         <div className={props.Tagin3}>{props.price3}</div>
        </div>

        <img src={props.image3} alt={props.alt3} loading='lazy'/>

        <div className={props.pricename}>  <p style={{ fontSize: props.fontSize1 }}>{props.title3}</p>
        <p>{props.priceofprod3}  
        <li className="realprice1" >{props.realprice3}</li>
        </p>

        </div>
         <li className='productpara' >
       
       {props.underline3}
      </li>
      <div className="detailsBlock-2 justify"> {ratingComponent}
     <span className="detailsBlock-2-span">{props.ratingno3}</span>   
      </div>
      <div>{props.button}</div>
      </div>
      </Link>
      <Link to={props.bestlink4} > 
      <div className="grid__item">
      <div className={props.Tag4}>
         <div className={props.Tagin4}>{props.price4}</div>
        </div>

        <img src={props.image4} alt={props.alt4} loading='lazy'/>
        <div className={props.pricename}>  <p style={{ fontSize: props.fontSize1 }}>{props.title4}</p>
        <p>{props.priceofprod4}
        <li className="realprice1" >{props.realprice4}</li>
        </p>

        </div>
         <li className='productpara' >
       
       {props.underline4}
      </li>
      <div className="detailsBlock-2 justify"> {ratingComponent}
     <span className="detailsBlock-2-span">{props.ratingno4}</span>   
      </div>
     <div>{props.button}</div>
      </div>
      </Link>

      
    </div>
  </div>
</div>


  )
}

export default Bestseller




